import {
  MARKETPLACES_REGIONS,
  MARKETPLACES_IN_TRANSITION,
  MARKETPLACES
} from 'constants/countries'
import { KEYWORD_ENGINE_SEARCH_TYPES } from 'constants/keyword_engine_search_types'
import { SEGMENT_LOCATION } from 'constants/listing_builder/listing_builder'
import {
  getPathData,
  sendSegmentIdentity,
  sendSegmentTrackEvent,
  safeSegmentCall
} from 'services/segment'
import { stripURLParams } from 'helpers/url'

import { buildSegmentSubscriptionData } from 'helpers/account'
import { getCurrencyCode, parseCurrency } from 'helpers/currency'
import { getSearchType } from 'helpers/keyword_engine'
import { getAllVariantsValue } from 'helpers/keyword_scout/request'

import { isMobile } from 'react-device-detect'
import { toInteger } from './formatters'
import { logExceptionNoConsole } from '../config/sentry'

export const getProductForSegment = appType => {
  if (typeof appType !== 'string' || appType === 'default') {
    return 'Jungle Scout'
  }

  // Capitalize first letter
  return `${appType.charAt(0)?.toUpperCase()}${appType.slice(1)}`
}

export const getSegmentAnonymousId = () => {
  return window.analytics?.user?.().anonymousId?.()
}

const sendSignUpEvent = (
  subscriptionData,
  revenue,
  email,
  existingUserPurchase = false
) => {
  const {
    planName,
    trialStartDate,
    trialExpirationDate,
    product,
    paidSeats,
    usedSeats,
    extension
  } = subscriptionData

  if (!existingUserPurchase) {
    sendSegmentTrackEvent('Account Created', {
      email,
      product,
      paidSeats,
      usedSeats
    })
  }

  if (trialStartDate) {
    sendSegmentTrackEvent('Trial Started', {
      trialPlanName: planName,
      trialStartDate,
      trialExpirationDate,
      product,
      extension
    })
  }

  sendSegmentTrackEvent('Subscription Started', subscriptionData)

  sendSegmentTrackEvent('Subscription Paid', {
    ...subscriptionData,
    revenue
  })
}

export const sendSegmentSignUpEvent = (
  purchaseInfo,
  membershipInfo,
  user,
  existingUserPurchase = false
) => {
  const { price = 0 } = purchaseInfo

  const revenue = toInteger(price)

  const subscriptionData = buildSegmentSubscriptionData({
    membershipInfo,
    user
  })

  sendSegmentIdentity(user.id, {
    email: user.email,
    id: user.id
  })
  sendSignUpEvent(subscriptionData, revenue, user.email, existingUserPurchase)
}

export const getOptimisedLqs = lqs => {
  const integerVal = parseInt(lqs, 10)
  return Number.isNaN(integerVal) || integerVal < 10
    ? lqs
    : Math.round(integerVal / 10)
}

export const sendSegmentMWSStepEvent = (
  stepName,
  region,
  availableMarketplaces,
  primary_marketplace_id,
  appType,
  label,
  sc_email
) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Amazon Account Connection Step Completed', {
      stepName,
      region: MARKETPLACES_REGIONS[region],
      primaryMarketplace: availableMarketplaces[region]?.filter(
        item => item.value === primary_marketplace_id
      )[0]?.name,
      accountNickname: label,
      sellerCentralEmail: sc_email,
      product: getProductForSegment(appType),
      pageURL: stripURLParams(window.location.href)
    })
  })
}

export const sendSegmentFileDownloadedEvent = (
  appType,
  tab,
  name,
  type,
  extraProps = {}
) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('File Exported', {
      product: getProductForSegment(appType),
      tab,
      name,
      type: type || 'CSV',
      ...extraProps
    })
  })
}

export const sendSegmentExportTriggeredEvent = (
  appType,
  tab,
  name,
  type,
  extraProps = {}
) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('File Export Triggered', {
      product: getProductForSegment(appType),
      tab,
      name,
      type: type || 'CSV',
      ...extraProps
    })
  })
}

export const sendCtaClickEvent = ({
  destination,
  text,
  location,
  appType = 'default',
  type = 'button',
  pageUrl = null,
  category,
  pageName,
  tab,
  extraProps = {}
}) => {
  safeSegmentCall(() => {
    // Just a safety check, because if someone sends something like an event
    //  or some types of React components it can cause a circular dependency
    //  and crash the action
    const sanitizedText = typeof text === 'string' ? text : 'CTA Clicked'
    const currentURL = pageUrl || window.location.href

    const props = {
      destination,
      type,
      text: sanitizedText,
      pageUrl: currentURL,
      product: getProductForSegment(appType),
      location,
      ...extraProps
    }

    if (category) {
      props.category = category
    }
    if (pageName) {
      props.pageName = pageName
    }
    if (tab) {
      props.tab = tab
    }

    sendSegmentTrackEvent('CTA Clicked', props)
  })
}

export const sendSetupWizardStepEvent = ({
  appType = 'default',
  stepName,
  status = 'completed',
  extraProps = {}
}) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Setup Wizard Step', {
      stepName,
      device: isMobile ? 'mobile' : 'desktop',
      status,
      product: getProductForSegment(appType),
      ...extraProps
    })
  })
}

export const sendNavClickEvent = ({ navItem, location, extraProps = {} }) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Nav Item Clicked', {
      navItem,
      location,
      ...extraProps
    })
  })
}

export const sendSegmentKeywordListCreateEvent = (
  listName,
  numberOfKeywords,
  tab,
  method
) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Keyword List Created', {
      product: 'Jungle Scout',
      listName,
      numberOfKeywords,
      tab,
      method
    })
  })
}

export const sendSegmentKeywordEvent = (
  eventName,
  appType,
  listName,
  keywords,
  keywordsInTable,
  method
) => {
  const getEaseToRankValue = value => {
    switch (true) {
      case value >= 0 && value <= 30:
        return 'Very Difficult'
      case value >= 31 && value <= 50:
        return 'Difficult'
      case value >= 51 && value <= 69:
        return 'Somewhat Difficult'
      case value >= 70 && value <= 89:
        return 'Moderate'
      case value >= 90 && value <= 100:
        return 'Easy'
      default:
        return undefined
    }
  }
  safeSegmentCall(() => {
    sendSegmentTrackEvent(eventName, {
      tab: 'Keyword Lists',
      product: getProductForSegment(appType),
      listName,
      numberOfKeywords: keywordsInTable || keywords?.length,
      method: method || 'manual',
      keywords: keywords?.map(keyword => ({
        marketplace: MARKETPLACES_IN_TRANSITION[keyword.country]?.name,
        keywordText: keyword.name,
        '30SearchVol': keyword.estimatedExactSearchVolume,
        '30DayTrend': keyword.monthlyTrend,
        dominantCategory: keyword.category,
        ppcBidExact: keyword.exactSuggestedBidMedian,
        ppcBidBroad: keyword.broadSuggestedBidMedian,
        easeToRank: getEaseToRankValue(keyword.easeOfRankingScore)
      }))
    })
  })
}

export const sendSegmentKeywordScoutSearchedEvent = (
  appType,
  country,
  searchTerm,
  tags,
  allVariantsToggle
) => {
  const searchType = getSearchType(tags)
  const isAsinSearch = searchType !== KEYWORD_ENGINE_SEARCH_TYPES.keyword

  safeSegmentCall(() => {
    sendSegmentTrackEvent('Keyword Scout Searched', {
      product: getProductForSegment(appType),
      marketplace: MARKETPLACES_IN_TRANSITION[country]?.name,
      query: searchTerm || tags.join(','),
      inputType: isAsinSearch ? 'ASIN' : 'keyword',
      allVariants: getAllVariantsValue(tags, allVariantsToggle)
    })
  })
}

export const sendAddProductToTrackEvent = (
  groupName,
  page,
  appType,
  products,
  niche,
  method,
  removingEvent
) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent(removingEvent || 'Product Added', {
      groupName,
      tab: page,
      product: getProductForSegment(appType),
      method: method || 'manual',
      numberOfObjects: products?.length,
      products:
        products?.length &&
        products.map(product => {
          const { rating, startDate, width, height, name } = product
          const title =
            typeof name === 'string' ? name : name?.props?.product.name

          return {
            marketplace: MARKETPLACES[product.country]?.name,
            niche,
            title,
            asin: product.asin,
            brand: product.brand,
            imageUrl: product.imageUrl,
            category: product.category,
            variantDifferences: product.hasVariants,
            mthlyRevenue: product.estRevenue,
            mthlySales: product.estimatedSales,
            price: product.price,
            rank: product.rank,
            reviews: product.nReviews,
            lqs: getOptimisedLqs(product.listingQualityScore),
            sellers: product.nSellers,
            dateFirstAvailable:
              (startDate && new Date(startDate).toISOString()) || null,
            rating: rating?.props
              ? rating.props.children[1]?.props.rating
              : rating,
            dimensions:
              (height && width && `height: ${height}, width: ${width}`) || null,
            weight: product.weight
          }
        })
    })
  })
}

export const sendSegmentAsinInKSSearchedEvent = (
  appType,
  product,
  selectedCountry,
  tab
) => {
  safeSegmentCall(() => {
    const getRating = value =>
      value && typeof value === 'number' ? value : undefined

    const listedAtValue = parseInt(
      product.listedAt || product.estimatedListedAt,
      10
    )

    const ratingValue =
      getRating(product.ratingData) || getRating(product.rating)
    sendSegmentTrackEvent('ASIN in Keyword Scout Searched', {
      tab,
      product: getProductForSegment(appType),
      marketplace: MARKETPLACES[selectedCountry]?.name,
      title: product.name,
      asin: product.asin,
      brand: product.brand,
      imageUrl: product.imageUrl,
      category: product.category,
      variantDifferences: product.variantAsinsCount || 0,
      mthlyRevenue: product.estRevenue,
      mthlySales: product.estimatedSales,
      price: product.price,
      rank: product.rank,
      reviews: product.nReviews,
      lqs: getOptimisedLqs(product.listingQualityScore),
      sellers: product.nSellers,
      dateFirstAvailable: listedAtValue
        ? new Date(listedAtValue).toISOString()
        : null,
      rating: ratingValue,
      dimensions: `width: ${product.width}, height: ${product.height}`,
      weight: product.weight
    })
  })
}

export const sendSegmentSearchResultViewedEvent = (page, appType) =>
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Search Result Viewed', {
      tab: page,
      product: getProductForSegment(appType)
    })
  })

export const sendEvaluationEvent = ({
  contentIsGood,
  location = SEGMENT_LOCATION,
  section,
  content,
  model = 'Davinci'
}) =>
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Evaluation Submitted', {
      contentIsGood,
      location,
      section,
      content,
      model
    })
  })

export const sendUpgradeModalEvent = options =>
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Upgrade Modal Displayed', {
      ...options,
      pageUrl: stripURLParams(window.location?.href),
      product: getProductForSegment('default')
    })
  })

export const sendSegmentSalesAnalyticsFilteredEvent = ({
  tab,
  pageName = null,
  query = null,
  filters = null,
  category = null,
  type = null,
  text = null
}) =>
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Sales Analytics Filtered', {
      tab,
      query,
      filters,
      pageName,
      category,
      type,
      text
    })
  })

export const sendSegmentErrorMsgShownEvent = (appType, options) => {
  safeSegmentCall(() => {
    sendSegmentTrackEvent('Error Message Shown', {
      ...options,
      pageUrl: window.location?.href,
      product: getProductForSegment(appType),
      tab: getPathData(window.location?.hash?.substr(1))?.name
    })
  })
}

export const sendProductInAmazonViewed = (product, appType = 'default') => {
  if (!product) {
    return
  }

  safeSegmentCall(() => {
    const page = window.location.hash.substr(1)
    const tab = getPathData(page)?.name

    const listedAtValue = parseInt(
      product.listedAt || product.estimatedListedAt,
      10
    )

    const ratingValue =
      product.ratingData ||
      (typeof product.rating === 'number' ? product.rating : undefined)
    const dimensions =
      (product.width || product.height) &&
      `width: ${product.width}, height: ${product.height}`

    const currencyCode = getCurrencyCode(product.country)
    const revenue =
      product.estRevenue && parseCurrency(product.estRevenue, currencyCode)

    sendSegmentTrackEvent('Product in Amazon Viewed', {
      tab,
      product: getProductForSegment(appType),
      niche: product.niche,
      title: product.name,
      asin: product.asin,
      brand: product.brand,
      imageUrl: product.imageUrl,
      category: product.category,
      variantDifferences: product.variantAsinsCount,
      mthlyRevenue: revenue,
      mthlySales: product.estimatedSales,
      price:
        product.price && parseCurrency(product.price, product.currency_code),
      rank: product.rank,
      reviews: product.nReviews,
      lqs: getOptimisedLqs(product.listingQualityScore),
      sellers: product.nSellers,
      dateFirstAvailable: listedAtValue
        ? new Date(listedAtValue).toISOString()
        : null,
      rating: ratingValue,
      dimensions,
      weight: product.weight
    })
  })
}

export const sendSegmentIdentifyWhenFullStoryReady = (userId, intervalId) => {
  if (window?.fsIsReady) {
    try {
      sendSegmentIdentity(userId)
    } catch (e) {
      logExceptionNoConsole(e)
    }

    clearInterval(intervalId)
  }
}
